import React from 'react';

const Spinner = ({ minHeight = '100%', position = 'center' }) => {
  return (
    <div
      style={{ minHeight }}
      className={`flex ${
        position === 'left' ? 'justify-start' : 'justify-center'
      } items-center`}
    >
      <svg className='spinner' viewBox='0 0 50 50'>
        <circle
          className='path'
          cx='25'
          cy='25'
          r='20'
          fill='none'
          strokeWidth='5'
        ></circle>
      </svg>
    </div>
  );
};

export default Spinner;
