// AUTHENTICATION
export const START_LOADING_CONTENT = 'START_LOADING_CONTENT';
export const AUTHENTICATION_LOGIN = 'AUTHENTICATION_LOGIN';
export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';

// LESSONS
export const BUILD_LESSON = 'BUILD_LESSON';
export const GET_LESSONS = 'GET_LESSONS';
export const GET_LESSON = 'GET_LESSON';
export const DELETE_LESSON = 'DELETE_LESSON';
export const LESSON_POST_ERROR = 'LESSON_POST_ERROR';
export const SAVED_LESSON = 'SAVED_LESSON';

// OPTIONS
export const GET_THRESHOLD_CONCEPTS = 'GET_THRESHOLD_CONCEPTS';
export const GET_INFORMATION_LITERACY_OBJECTIVES =
  'GET_INFORMATION_LITERACY_OBJECTIVES';
export const GET_MODULES = 'GET_MODULES';
export const GET_LIBRARIANS = 'GET_LIBRARIANS';
export const ADD_CUSTOM_MODULES = 'ADD_CUSTOM_MODULE';
export const OPTIONS_ERROR = 'OPTIONS_ERROR';

// COURSES
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSE = 'GET_COURSE';
export const CLEAR_COURSE = 'CLEAR_COURSE';
